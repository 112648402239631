* {
  box-sizing: border-box;
  margin: 0;
}

link {
  text-decoration: none !important;
}

.py-70 {
  padding: 70px 0;
}


/* ----------------------- start footer----------------------- */
footer {
  background-color: #06042E;
}

footer .div1 {
  color: #8B8EAB;
}

footer .div1 img {
  margin-bottom: 20px;
}

footer h6 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

footer p {
  font-size: 16px;
  font-weight: 500;
  color: #b2bbcc;
  position: relative;
  transition: all .5s;
}

footer p a:hover {

  color: #ffc224;

}

footer .div4 i {
  font-size: 16px;
  color: #b2bbcc;
}

footer .div4 img {
  margin-top: 30px;
  margin-right: 10px;
}

footer .div5 {
  display: flex;
  justify-content: space-between;
  color: #b2bbcc;
}

footer .div5 a {
  color: #b2bbcc;
  margin-right: 10px;
  transition: all .5s;
}

footer .div5 a:hover {
  color: #ffc224;

}

/* ----------------------- End footer----------------------- */


/* ----------------------- start contact page ----------------------- */
.con1 {
  height: 300px;
  background-image: url(imges/breadcrumb-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.con1 .img1 {
  position: absolute;
  left: 100px;
  top: 62px;
}

.con1 .img2 {
  position: absolute;
  right: 32%;
  top: 21%;
}

.con1 .img3 {
  position: absolute;
  right: 20%;
  bottom: 15%;
}

.con1 .img4 {
  position: absolute;
  right: 11%;
  top: 16%;
}

.con1 h1 {
  margin-top: 30px;
  font-size: 40px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #161439;
}

.con1 p a {
  color: #1c1a4a;
}

.con1 p i {
  color: #7f7e97;
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
  margin-right: 8px;
}

/* ----------------------------- */
.con2 {
  height: auto;
}

.con2 .icon {
  width: 75px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #5751e1;
  color: #fff;
  font-size: 25px;
  margin-right: 15px;
}

.con2 .addres {
  display: flex;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #f7f7fa;
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 20px;
}

.con2 .addres h3 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #161439;
  font-weight: 600;
}

.con2 .addres p {
  font-weight: 500;
  color: #6d6c80;
  font-size: 16px;
  margin-bottom: 0;

}

.con2 .addres p a {
  color: #6d6c80;
}

.con2 .addres p a:hover {
  color: #5751e1;
}

.con2 .form {
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: #f7f7fa;
  border-radius: 5px;
  padding: 40px;
}

.con2 .form h3 {
  font-size: 28px;
  color: #161439;
}

.con2 .form p {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #6d6c80;
}

.con2 .form input {
  width: 100%;
  border: 1px solid #e1e4e7;
  border-radius: 5px;
  display: block;
  background: #fff;
  font-weight: 400;
  font-size: 16px;
  color: #6d6c80;
  padding: 11px 20px;
  height: 50px;
}

.con2 .form .btn {

  background: #ffc224;
  color: #161439;
  box-shadow: 0 0 0 0 #050071;
  transition: 0.3s linear;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.12;
  margin-bottom: 0;
  padding: 16px 30px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  border-radius: 50px;
  white-space: nowrap;
  box-shadow: 4px 6px 0px 0px #050071;
}

.con2 .form .btn:hover {
  background: #5751e1;
  color: #fff;
  box-shadow: 0 0 0 0 #050071;
  border-color: #5751e1;

}

.con2 .form .btn i {
  margin-left: 6px;
}

/* ------------------ */
.con3 {
  height: auto;
}

.con3 iframe {
  width: 100%;
  height: 70vh;
}

/* ----------------------- End contact page ----------------------- */

/* ----------------------- start login page ----------------------- */
.log1 {
  height: auto;
}

.log1 .form {
  margin: auto;
  padding: 40px;
  border: 1px solid #e1e1e1;
  background: #f7f7fa;
  position: relative;
  top: 0;
  left: 0;

}

.log1 .form h3 {
  font-size: 28px;
  color: #161439;
  font-weight: 600;
}

.log1 .form p {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #6d6c80;
  line-height: 1.75;
}

.log1 .googel {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 16px;
  gap: 10px;
  padding: 10px 20px;

}

.log1 .googel a {
  color: #1c1a4a;
}

.log1 .googel:hover a {
  color: #5751e1;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-out 0s;
}

.log1 .googel:hover {
  border: 1px solid #5751e1;

}

.log1 .text {
  position: relative;
  text-align: center;
  padding-block-end: 35px;
  margin-block-start: 35px;
}

.log1 .form1 input {
  width: 100%;
  padding: 14px 20px;
  font-size: 16px;
  color: #161439;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 5px;
  line-height: 1;

}

.log1 .form .btn {
  width: 100%;
  border: 2px solid #000;
  background: #ffc224;
  color: #161439;
  box-shadow: 0 0 0 0 #050071;
  transition: 0.3s linear;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.12;
  margin-bottom: 0;
  padding: 16px 30px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  border-radius: 50px;
  white-space: nowrap;
  box-shadow: 4px 6px 0px 0px #050071;
}

.log1 .form .btn:hover {
  background: #5751e1;
  color: #fff;
  box-shadow: 0 0 0 0 #050071;
  border-color: #5751e1;

}

.log1 .form i {
  margin-left: 10px;
}

.log1 .sign {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;

  margin-top: 40px;
}

.log1 span {
  font-size: 16px;
  color: #161439;
  padding: 20px;
  top: 220px;
  left: 45%;
  position: absolute;
  background: #f7f7fa;

}

footer .div5 {
  display: flex;
  justify-content: space-between;
  color: #b2bbcc;
  background-color: #1C1A4A;
}

@media screen and (max-width:992px) {
  .log1 span {
    top: 250px;
  }
}

/* ----------------------- End login page ----------------------- */

/* ----------------------- start sign up page ----------------------- */
.sig1 {
  height: auto;
}

.sig1 .form {
  margin: auto;
  padding: 40px;
  border: 1px solid #e1e1e1;
  background: #f7f7fa;
  position: relative;
  top: 0;
  left: 0;
}

.sig1 .form h3 {
  font-size: 28px;
  color: #161439;
  font-weight: 600;
}

.sig1 .form p {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #6d6c80;
  line-height: 1.75;
}

.sig1 .googel {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 16px;
  gap: 10px;
  padding: 10px 20px;
}

.sig1 .googel a {
  color: #1c1a4a;
}

.sig1 .googel:hover a {
  color: #5751e1;
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-out 0s;
}

.sig1 .googel:hover {
  border: 1px solid #5751e1;
}

.sig1 .text {
  position: relative;
  text-align: center;
  padding-block-end: 35px;
  margin-block-start: 35px;
}

.sig1 .form1 input {
  width: 100%;
  padding: 14px 20px;
  font-size: 16px;
  color: #161439;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 5px;
  line-height: 1;
}

.sig1 .form .btn {
  width: 100%;
  border: 2px solid #000;
  background: #ffc224;
  color: #161439;
  box-shadow: 0 0 0 0 #050071;
  transition: 0.3s linear;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.12;
  margin-bottom: 0;
  padding: 16px 30px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  border-radius: 50px;
  white-space: nowrap;
  box-shadow: 4px 6px 0px 0px #050071;
}

.sig1 .form .btn:hover {
  background: #5751e1;
  color: #fff;
  box-shadow: 0 0 0 0 #050071;
  border-color: #5751e1;
}

.sig1 .form i {
  margin-left: 10px;
}

.sig1 .sign {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.sig1 label {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
  color: #161439;
}

.sig1 span {
  font-size: 16px;
  color: #161439;
  padding: 20px;
  top: 220px;
  left: 45%;
  position: absolute;
  background: #f7f7fa;

}

/* ----------------------- End sign up page ----------------------- */



html {
  scroll-behavior: smooth;
}

#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  min-height: calc(100vh - 60px);
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff !important;
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #012970;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebara
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 35px;
  left: 0;
  bottom: 0;
  width: 300px;
  /* Default sidebar width */
  transition: all 0.3s ease;
  z-index: 996;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed {
  transform: translateX(-100%);
  /* Move off-screen for LTR languages */
}

.sidebar.sidebar-right.closed {
  transform: translateX(100%);
  width: 100%;
  /* Move off-screen for RTL languages */
}

.sidebar.sidebar-right {
  left: auto;
  right: 0;
}

.margin-right-300 {
  margin-right: 300px;
}

.full-width {
  margin-right: 0;
  /* Reset margin */
  width: 100%;
  /* Ensure full width */
}
@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: 300px;
  }

  /* When language is Arabic */
  body.rtl #main,
  body.rtl #footer {
    margin-left: 0;
    margin-right: 300px;
    /* Optional: Adjust if needed for Arabic layout */
  }
}
@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}




@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {

  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #4154f1;
  transition: 0.3;
  background: #f6f9ff;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: #4154f1;
}

.sidebar-nav .nav-link.collapsed {
  color: #012970;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}

.booking .nav-tabs .nav-link {
  border: none;
  font-weight: 600;
  position: relative;
}

.booking .nav-tabs .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue-1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.booking .nav-tabs .nav-link.active {
  color: var(--color-blue-1) !important;
}

.booking .nav-tabs .nav-link.active::after {
  opacity: 1;
}

.table>thead {
  vertical-align: middle;
}

table.table td {
  padding: 15px 30px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

.table> :not(caption)>*>* {
  border-bottom-width: 0;
}

table.table tbody>tr {
  border-top: none;
  border-bottom: 1px dashed var(--color-border);
}

table.table th {
  font-weight: 500;
  padding: 20px 30px;
  line-height: 1.2;
  min-width: 130px;
  font-size: 17px;
}

table.table th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table th:last-child {
  border-radius: 0 8px 8px 0;
}

.scroll-bar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-bar-1::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

table.table .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 120px;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
}

table.table .dropdown-menu a {
  padding: 10px;
}

.form-input {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
}

.form-input textarea,
.form-input input {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 0 15px;
  padding-top: 25px;
  min-height: 70px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-input label {
  position: absolute;
  z-index: 2;
  top: 26px;
  padding: 0 15px;
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-input textarea:focus~label,
.form-input textarea:valid~label,
.form-input input:focus~label,
.form-input input:valid~label {
  transform: translateY(-12px);
}

.upload-image label {
  cursor: pointer;
}

.upload-image .w-200 {
  width: 200px;
  max-width: 100%;
}

.upload-image .ratio::before {
  padding-bottom: 100%;
}

.upload-image .border-type-1 {
  border: 1px dashed var(--color-blue-1);
}

.profile .nav-tabs .nav-link {
  border: none;
  color: var(--color-dark-1);
  font-weight: 500;
}

.profile .nav-tabs .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

.profile .card-title {
  color: #012970;
}

.profile .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.include-ul,
.exclude-ul,
.exclude-ul li,
.include-ul li {
  list-style-type: auto;
}

.nav-tabs .nav-item {
  margin-right: 10px !important;
  /* Adjust spacing between tabs if needed */
}

.nav-tabs .nav-link {
  display: inline-block !important;
  padding: 10px 15px !important;
  /* Ensure consistent padding for all tabs */
}

.nav-tabs .nav-link.active {
  font-weight: bold !important;
  /* Highlight active tab */
  background-color: #f8f9fa !important;
  /* Optional: Add background to active tab */
  border: 1px solid #dee2e6 !important;
}

/*----------------genealogy-scroll----------*/

.genealogy-scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.genealogy-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}

.genealogy-scroll::-webkit-scrollbar-thumb {
  background: #212121;
  border-radius: 10px;
  transition: 0.5s;
}

.genealogy-scroll::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  transition: 0.5s;
}


/*----------------genealogy-tree----------*/
.genealogy-body {
  white-space: nowrap;
  overflow-y: hidden;
  min-height: 500px;
  padding-top: 10px;
  text-align: center;
}

/* Full Tree Container with Light Background */



.genealogy-tree li:only-child::after,
.genealogy-tree li:only-child::before {
  display: none;
}

.genealogy-tree li:only-child {
  padding-top: 0;
}

.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
  border: 0 none;
}

.genealogy-tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.genealogy-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/* Ensure vertical connector lines are fully visible */
.genealogy-tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
  transform: translateX(-50%);
}

/* Ensure horizontal lines are fully connected */
.genealogy-tree li::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 2px;
}

/* Fix the connection at child nodes */
.genealogy-tree li::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  height: 20px;
  transform: translateX(-50%);
}

/* Ensure child nodes align properly */
.genealogy-tree ul {
  position: relative;
  padding-left: 0;
  margin-left: 0;
}

.genealogy-tree ul li {
  position: relative;
  list-style-type: none;
  text-align: center;
  padding-top: 20px;
}

.genealogy-tree ul {

  list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  /* Align child nodes to the left */


}

.tree-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align tree to the left */
  width: 100%;
  padding: 20px;
}

.tree-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

button.btn-sm {
  margin-top: 10px;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.genealogy-tree li {
  text-align: center;
  margin: 10px;
  position: relative;
}

/* General Styles */
.genealogy-tree {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  overflow-x: auto;
  white-space: nowrap;
  /* Prevent wrapping */
  /* padding-left: 550px; */
}

.genealogy-tree ul {
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: center;
  position: relative;
}

.genealogy-tree li {
  text-align: center;
  position: relative;
  margin: 0 20px;
}

/* Node Container */
.member-view-boxx {
  display: inline-block;
  background: #c14a4a;
  border-radius: 10px;
  text-align: center;
  position: relative;

}

.member-view-box img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.member-details h3 {
  color: white;
  font-size: 14px;
  margin: 0;
}

/* Connecting Lines */
.genealogy-tree li::before,
.genealogy-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 20px;
  border-top: 1px solid #fff;
}

.genealogy-tree li::before {
  right: 50%;
  border-right: 1px solid #fff;
}

.genealogy-tree li::after {
  left: 50%;
  border-left: 1px solid #fff;
}

/* Remove lines for the first (root) node */
.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
  border: none;
}

.children-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}




.tooltip {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1000;
  font-size: 12px;
  text-align: left;
  white-space: pre-wrap;
}

.genealogy-tree li a {
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.genealogy-tree li a:hover+ul li::after,
.genealogy-tree li a:hover+ul li::before,
.genealogy-tree li a:hover+ul::before,
.genealogy-tree li a:hover+ul ul::before {
  border-color: #fbba00;
}

.bg-ligh {
  background-color: #007bff !important;
}

/*--------------memeber-card-design----------*/
.member-view-box {
  background-color: #31373a;
  /* Dark background for nodes */
  color: white;
  /* Text color */
  border-radius: 10px;
  width: 200px;
  margin: 10px auto;
  /* Center each node */
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.member-image {
  background-color: #31373a;
  border-radius: 10px;
  padding: 10px;
}


.member-image img {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #000;
  z-index: 1;
}

:root {
  --font-primary: "Jost", sans-serif;
}

:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-border: #dddddd;
  --color-dark-1: #051036;
  --color-dark-2: #0d2857;
  --color-dark-3: #13357b;
  --color-dark-4: #163c8c;
  --color-light-1: #697488;
  --color-light-2: #f5f5f5;
  --color-light-3: #fbfcff;
  --color-blue-1: #3554d1;
  --color-blue-2: #e5f0fd;
  --color-yellow-1: #f8d448;
  --color-yellow-3: #ffc700;
  --color-yellow-4: #fff8dd;
  --color-red-2: #f1416c;
  --color-red-3: #fff5f8;
}

.redcol {
  background-color: #db472d !important;
}

body {
  padding: 0;
  font-family: var(--font-primary);
  width: 100%;
  background-color: white;
  color: var(--color-dark-1);
  font-size: 16px;
  line-height: 1.875;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.45;
  font-weight: 600;
}

a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
  font-size: 16px;
  color: var(--color-light-1);
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
}

button:not(.button):focus {
  border: 0;
}

select,
input,
textarea {
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.p-70 {
  padding: 70px 0 !important;
}

.bg-light-2 {
  background-color: var(--color-light-2) !important;
}

.bg-blue-2 {
  background-color: var(--color-blue-2) !important;
}

.button.md {
  padding: 14px 30px;
}

.button.blue-1:not(:disabled):hover {
  border-color: var(--color-blue-1);
  background-color: var(--color-blue-1) !important;
  color: white !important;
}

.layout-py-lg {
  padding: 120px 0;
}

.layout-py-md {
  padding: 60px 0;
}

.text-24 {
  font-size: 24px;
}

.text-22 {
  font-size: 22px;
}

.text-18 {
  font-size: 18px;
}

.text-16 {
  font-size: 16px;
}

.text-15 {
  font-size: 15px;
}

.text-14 {
  font-size: 14px;
}

.text-12 {
  font-size: 12px;
}

.text-dark-1 {
  color: var(--color-dark-1);
}

.fw-500 {
  font-weight: 500;
}

.text-light-1 {
  color: var(--color-light-1);
}

.lh-14 {
  line-height: 1.4 !important;
}

.border-top-light {
  border-top: 1px solid var(--color-border);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.dark-1:not(:disabled):hover {
  border-color: var(--color-dark-1);
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.single-field input {
  border-radius: 4px;
  padding: 17px 20px;
  font-size: 15px;
  letter-spacing: 0.02em;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.underline {
  text-decoration: underline;
}

.bg-blue-1 {
  background-color: var(--color-blue-1);
}

.text-20 {
  font-size: 20px;
}

.fw-600 {
  font-weight: 600;
}

.text-30 {
  font-size: 30px;
}

.text-26 {
  font-size: 26px;
}

.text-light-1 {
  color: var(--color-light-1);
}

.text-yellow-1 {
  color: var(--color-yellow-1);
}

.text-10 {
  font-size: 10px;
}

.lh-15 {
  line-height: 1.5 !important;
}

.text-blue-1 {
  color: var(--color-blue-1);
}

.bg-blue-1-05 {
  background-color: rgba(53, 84, 209, 0.05);
}

.rounded-100 {
  border-radius: 100px !important;
}

.shadow-3 {
  box-shadow: 0px 10px 30px 0px #05103608;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.text-7 {
  font-size: 7px;
}

.fw-400 {
  font-weight: 400;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.size-40 {
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-border);
}

.text-40 {
  font-size: 40px;
}

.text-yellow-3 {
  color: var(--color-yellow-3);
}

.bg-yellow-4 {
  background-color: var(--color-yellow-4) !important;
}

.text-red-2 {
  color: var(--color-red-2);
}

.bg-red-3 {
  background-color: var(--color-red-3) !important;
}







/**
* Template Name: FlexStart
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Updated: Nov 01 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Nunito", sans-serif;
  --nav-font: "Poppins", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff;
  /* Background color for the entire website, including individual sections */
  --default-color: #444444;
  /* Default color used for the majority of the text content across the entire website */
  --heading-color: #012970;
  /* Color for headings, subheadings and title throughout the website */
  --accent-color: #4154f1;
  /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff;
  /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff;
  /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #012970;
  /* The default color of the main navmenu links */
  --nav-hover-color: #4154f1;
  /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff;
  /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff;
  /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529;
  /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #4154f1;
  /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #f9f9f9;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: black !important;
  text-decoration: none !important;
  transition: 0.3s;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/* PHP Email Form Messages
------------------------------*/
.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 20px 0;
  transition: all 0.5s;
  z-index: 997;
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 36px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 15px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 4px;
  transition: 0.3s;
  font-weight: 500;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/* Index Page Header
------------------------------*/
.index-page .header {
  --background-color: rgba(255, 255, 255, 0);
}

/* Index Page Header on Scroll
------------------------------*/
.index-page.scrolled .header {
  --background-color: #ffffff;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 12px;
    font-size: 15px;
    font-family: var(--nav-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover>a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
  }
}

/* Listing Dropdown - Desktop */
@media (min-width: 1200px) {
  .navmenu .listing-dropdown {
    position: static;
  }

  .navmenu .listing-dropdown ul {
    margin: 0;
    padding: 10px;
    background: var(--nav-dropdown-background-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .listing-dropdown ul li {
    flex: 1;
  }

  .navmenu .listing-dropdown ul li a,
  .navmenu .listing-dropdown ul li:hover>a {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--nav-dropdown-color);
    background-color: var(--nav-dropdown-background-color);
  }

  .navmenu .listing-dropdown ul li a:hover,
  .navmenu .listing-dropdown ul li .active,
  .navmenu .listing-dropdown ul li .active:hover {
    color: var(--nav-dropdown-hover-color);
    background-color: var(--nav-dropdown-background-color);
  }

  .navmenu .listing-dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
}

/* Listing Dropdown - Mobile */
@media (max-width: 1199px) {
  .navmenu .listing-dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .listing-dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .listing-dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding-bottom: 50px;
  position: relative;
}

.footer .footer-newsletter {
  background-color: color-mix(in srgb, var(--accent-color), transparent 97%);
  border-top: 1px solid color-mix(in srgb, var(--accent-color), transparent 85%);
  border-bottom: 1px solid color-mix(in srgb, var(--accent-color), transparent 85%);
  padding: 50px 0;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
}

.footer .footer-newsletter .newsletter-form {
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 6px 8px;
  position: relative;
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter .newsletter-form:focus-within {
  border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type=email] {
  border: 0;
  padding: 4px;
  width: 100%;
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  color: var(--default-color);
}

.footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-newsletter .newsletter-form input[type=submit] {
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  margin: -7px -8px -7px 0;
  background: var(--accent-color);
  color: var(--contrast-color);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-newsletter .newsletter-form input[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: color-mix(in srgb, var(--accent-color), transparent 97%);
  border: 1px solid color-mix(in srgb, var(--accent-color), transparent 85%);
  font-size: 16px;
  color: var(--accent-color);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--contrast-color);
  background-color: var(--accent-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  margin-right: 3px;
  font-size: 12px;
  line-height: 0;
  color: var(--accent-color);
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  display: inline-block;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-about a {
  color: var(--heading-color);
  font-size: 24px;
  font-weight: 600;
  font-family: var(--heading-font);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 6px;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  position: relative;
}

.page-title .heading {
  padding: 80px 0;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
}

.page-title nav {
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 20px 0;
}

.page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.page-title nav ol li+li {
  padding-left: 10px;
}

.page-title nav ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  scroll-margin-top: 98px;
  overflow: clip;
}

@media (max-width: 1199px) {

  section,
  .section {
    scroll-margin-top: 56px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: color-mix(in srgb, var(--accent-color), transparent 90%);
  color: var(--accent-color);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  font-family: var(--default-font);
}

.section-title p {
  color: var(--heading-color);
  margin: 10px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  font-family: var(--heading-font);
}

.section-title p .description-title {
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 80px 0 60px 0;
  display: flex;
  align-items: center;
  background: url(imges/hero-bg.png) top center no-repeat;
  background-size: cover;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin: 5px 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px;
  border-radius: 4px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.hero .btn-get-started:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started:hover i {
  transform: translateX(5px);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  color: var(--default-color);
  font-weight: 600;
}

.hero .btn-watch-video i {
  color: var(--accent-color);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: var(--accent-color);
}

.hero .btn-watch-video:hover i {
  color: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 640px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .content {
  background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
  padding: 40px;
}

.about .content h3 {
  font-size: 14px;
  font-weight: 700;
  color: var(--accent-color);
  text-transform: uppercase;
}

.about .content h2 {
  font-size: 24px;
  font-weight: 700;
}

.about .content p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .content .btn-read-more {
  color: var(--contrast-color);
  background: var(--accent-color);
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
}

.about .content .btn-read-more span {
  font-family: var(--default-font);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .content .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .content .btn-read-more:hover i {
  transform: translateX(5px);
}

/*--------------------------------------------------------------
# Values Section
--------------------------------------------------------------*/
.values .card {
  background-color: var(--surface-color);
  color: var(--default-color);
  padding: 30px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: 0.3s;
  height: 100%;
  border: 0;
}

.values .card img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .card h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .card:hover {
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
}

.values .card:hover img {
  transform: scale(1);
}

/*--------------------------------------------------------------
# Stats Section
--------------------------------------------------------------*/
.stats .stats-item {
  background-color: var(--surface-color);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.stats .stats-item i {
  color: var(--accent-color);
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
}

.stats .stats-item span {
  color: var(--heading-color);
  font-size: 36px;
  display: block;
  font-weight: 600;
}

.stats .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--heading-font);
  font-size: 16px;
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .feature-box {
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  background: color-mix(in srgb, var(--accent-color), transparent 92%);
  color: var(--accent-color);
  line-height: 0;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Alt Features Section
--------------------------------------------------------------*/
.alt-features .icon-box {
  display: flex;
}

.alt-features .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.alt-features .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: var(--accent-color);
  margin-right: 15px;
}

.alt-features .icon-box p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  background-color: var(--surface-color);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 60px 30px;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
}

.services .service-item .icon {
  font-size: 36px;
  padding: 20px 20px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
  display: inline-block;
  line-height: 0;
  transition: 0.3s;
}

.services .service-item h3 {
  font-size: 24px;
  font-weight: 700;
}

.services .service-item .read-more {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
}

.services .service-item .read-more i {
  line-height: 0;
  margin-left: 5px;
  font-size: 18px;
}

.services .service-item.item-cyan {
  border-bottom: 3px solid #0dcaf0;
}

.services .service-item.item-cyan .icon {
  color: #0dcaf0;
  background: rgba(13, 202, 240, 0.1);
}

.services .service-item.item-cyan .read-more {
  color: #0dcaf0;
}

.services .service-item.item-cyan:hover {
  background: #0dcaf0;
}

.services .service-item.item-orange {
  border-bottom: 3px solid #fd7e14;
}

.services .service-item.item-orange .icon {
  color: #fd7e14;
  background: rgba(253, 126, 20, 0.1);
}

.services .service-item.item-orange .read-more {
  color: #fd7e14;
}

.services .service-item.item-orange:hover {
  background: #fd7e14;
}

.services .service-item.item-teal {
  border-bottom: 3px solid #20c997;
}

.services .service-item.item-teal .icon {
  color: #20c997;
  background: rgba(32, 201, 151, 0.1);
}

.services .service-item.item-teal .read-more {
  color: #20c997;
}

.services .service-item.item-teal:hover {
  background: #20c997;
}

.services .service-item.item-red {
  border-bottom: 3px solid #df1529;
}

.services .service-item.item-red .icon {
  color: #df1529;
  background: rgba(223, 21, 4, 0.1);
}

.services .service-item.item-red .read-more {
  color: #df1529;
}

.services .service-item.item-red:hover {
  background: #df1529;
}

.services .service-item.item-indigo {
  border-bottom: 3px solid #6610f2;
}

.services .service-item.item-indigo .icon {
  color: #6610f2;
  background: rgba(102, 16, 242, 0.1);
}

.services .service-item.item-indigo .read-more {
  color: #6610f2;
}

.services .service-item.item-indigo:hover {
  background: #6610f2;
}

.services .service-item.item-pink {
  border-bottom: 3px solid #f3268c;
}

.services .service-item.item-pink .icon {
  color: #f3268c;
  background: rgba(243, 38, 140, 0.1);
}

.services .service-item.item-pink .read-more {
  color: #f3268c;
}

.services .service-item.item-pink:hover {
  background: #f3268c;
}

.services .service-item:hover h3,
.services .service-item:hover p,
.services .service-item:hover .read-more {
  color: #fff;
}

.services .service-item:hover .icon {
  background: #fff;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-tem {
  background-color: var(--surface-color);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  height: 100%;
}

@media (min-width: 1200px) {
  .pricing .pricing-tem:hover {
    transform: scale(1.1);
    box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  }
}

.pricing h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.pricing .price {
  font-size: 36px;
  color: var(--heading-color);
  font-weight: 600;
  font-family: var(--heading-font);
}

.pricing .price sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .price span {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  font-weight: 300;
}

.pricing .icon {
  padding: 20px 0;
}

.pricing .icon i {
  font-size: 48px;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: var(--default-color);
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 25px;
}

.pricing ul li {
  padding-bottom: 10px;
}

.pricing ul .na {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
  text-decoration: line-through;
}

.pricing .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: var(--accent-color);
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--heading-font);
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid var(--accent-color);
}

.pricing .btn-buy:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: var(--accent-color);
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faq .faq-container .faq-item {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.faq .faq-container .faq-item h3 .num {
  color: var(--accent-color);
  padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-active h3 {
  color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio .portfolio-filters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin: 0 10px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-filters li:hover,
.portfolio .portfolio-filters li.filter-active {
  color: var(--accent-color);
}

.portfolio .portfolio-filters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-filters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-filters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-content {
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-content img {
  transition: 0.3s;
}

.portfolio .portfolio-content .portfolio-info {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
}

.portfolio .portfolio-content .portfolio-info h4 {
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  background-color: var(--accent-color);
}

.portfolio .portfolio-content .portfolio-info p {
  position: absolute;
  bottom: 10px;
  text-align: center;
  display: inline-block;
  left: 0;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}

.portfolio .portfolio-content .portfolio-info .preview-link,
.portfolio .portfolio-content .portfolio-info .details-link {
  position: absolute;
  left: calc(50% - 40px);
  font-size: 26px;
  top: calc(50% - 14px);
  color: #fff;
  transition: 0.3s;
  line-height: 1.2;
}

.portfolio .portfolio-content .portfolio-info .preview-link:hover,
.portfolio .portfolio-content .portfolio-info .details-link:hover {
  color: var(--accent-color);
}

.portfolio .portfolio-content .portfolio-info .details-link {
  left: 50%;
  font-size: 34px;
  line-height: 0;
}

.portfolio .portfolio-content:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-content:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  background-color: var(--surface-color);
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid var(--background-color);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }

  .testimonials .testimonial-item {
    margin: 40px 20px;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/
.team .team-member {
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
}

.team .team-member .member-img {
  position: relative;
  overflow: hidden;
}

.team .team-member .member-img:after {
  position: absolute;
  content: "";
  left: -1px;
  right: -1px;
  bottom: -1px;
  height: 100%;
  background-color: var(--surface-color);
  -webkit-mask: url("imges/team-shape.svg") no-repeat center bottom;
  mask: url("imges/team-shape.svg") no-repeat center bottom;
  -webkit-mask-size: contain;
  mask-size: contain;
  z-index: 1;
}

.team .team-member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: color-mix(in srgb, var(--background-color), transparent 60%);
  z-index: 2;
}

.team .team-member .social a {
  transition: color 0.3s;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin: 15px 12px;
  display: block;
  line-height: 0;
  text-align: center;
}

.team .team-member .social a:hover {
  color: var(--default-color);
}

.team .team-member .social i {
  font-size: 18px;
}

.team .team-member .member-info {
  padding: 10px 15px 20px 15px;
}

.team .team-member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
}

.team .team-member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.team .team-member .member-info p {
  font-style: italic;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.team .team-member:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
}

.team .team-member:hover .social {
  right: 8px;
  opacity: 1;
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients .swiper-slide img {
  transition: 0.3s;
  opacity: 0.5;
}

.clients .swiper-slide img:hover {
  opacity: 1;
}

.clients .swiper-wrapper {
  height: auto;
}

.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

/*--------------------------------------------------------------
# Recent Posts Section
--------------------------------------------------------------*/
.recent-posts .post-item {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.recent-posts .post-item .post-img img {
  transition: 0.5s;
}

.recent-posts .post-item .post-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--accent-color);
  color: var(--contrast-color);
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.recent-posts .post-item .post-content {
  padding: 30px;
}

.recent-posts .post-item .post-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.recent-posts .post-item .meta i {
  font-size: 16px;
  color: var(--accent-color);
}

.recent-posts .post-item .meta span {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.recent-posts .post-item hr {
  color: color-mix(in srgb, var(--default-color), transparent 80%);
  margin: 20px 0;
}

.recent-posts .post-item .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.recent-posts .post-item .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.recent-posts .post-item:hover .post-title,
.recent-posts .post-item:hover .readmore {
  color: var(--accent-color);
}

.recent-posts .post-item:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  background: color-mix(in srgb, var(--default-color), transparent 96%);
  padding: 30px;
}

.contact .info-item i {
  font-size: 38px;
  line-height: 0;
  color: var(--accent-color);
}

.contact .info-item h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-item p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: color-mix(in srgb, var(--default-color), transparent 96%);
  padding: 30px;
  height: 100%;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
  background: var(--accent-color);
  color: var(--contrast-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Portfolio Details Section
--------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.portfolio-details .portfolio-info {
  background-color: var(--surface-color);
  padding: 30px;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .service-box {
  background-color: var(--surface-color);
  padding: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.service-details .service-box+.service-box {
  margin-top: 30px;
}

.service-details .service-box h4 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.service-details .services-list {
  background-color: var(--surface-color);
}

.service-details .services-list a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  background-color: color-mix(in srgb, var(--default-color), transparent 96%);
  display: flex;
  align-items: center;
  padding: 12px 15px;
  margin-top: 15px;
  transition: 0.3s;
}

.service-details .services-list a:first-child {
  margin-top: 0;
}

.service-details .services-list a i {
  font-size: 16px;
  margin-right: 8px;
  color: var(--accent-color);
}

.service-details .services-list a.active {
  color: var(--contrast-color);
  background-color: var(--accent-color);
}

.service-details .services-list a.active i {
  color: var(--contrast-color);
}

.service-details .services-list a:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
  color: var(--accent-color);
}

.service-details .download-catalog a {
  color: var(--default-color);
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: 0.3s;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.service-details .download-catalog a:first-child {
  border-top: 0;
  padding-top: 0;
}

.service-details .download-catalog a:last-child {
  padding-bottom: 0;
}

.service-details .download-catalog a i {
  font-size: 24px;
  margin-right: 8px;
  color: var(--accent-color);
}

.service-details .download-catalog a:hover {
  color: var(--accent-color);
}

.service-details .help-box {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  margin-top: 30px;
  padding: 30px 15px;
}

.service-details .help-box .help-icon {
  font-size: 48px;
}

.service-details .help-box h4,
.service-details .help-box a {
  color: var(--contrast-color);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}

/*--------------------------------------------------------------
# Blog Posts Section
--------------------------------------------------------------*/
.blog-posts article {
  background-color: var(--surface-color);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
}

.blog-posts .post-img {
  max-height: 440px;
  margin: -30px -30px 0 -30px;
  overflow: hidden;
}

.blog-posts .title {
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-posts .title a {
  color: var(--heading-color);
  transition: 0.3s;
}

.blog-posts .title a:hover {
  color: var(--accent-color);
}

.blog-posts .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-posts .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-posts .meta-top ul li+li {
  padding-left: 20px;
}

.blog-posts .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-posts .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-posts .content {
  margin-top: 20px;
}

.blog-posts .content .read-more {
  text-align: right;
}

.blog-posts .content .read-more a {
  background: var(--accent-color);
  color: var(--contrast-color);
  display: inline-block;
  padding: 8px 30px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog-posts .content .read-more a:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Blog Pagination Section
--------------------------------------------------------------*/
.blog-pagination {
  padding-top: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog-pagination li a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-pagination li a.active,
.blog-pagination li a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.blog-pagination li a.active a,
.blog-pagination li a:hover a {
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Blog Details Section
--------------------------------------------------------------*/
.blog-details {
  padding-bottom: 30px;
}

.blog-details .article {
  background-color: var(--surface-color);
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog-details .title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-details .content {
  margin-top: 20px;
}

.blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog-details .content blockquote {
  overflow: hidden;
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog-details .content blockquote p {
  color: var(--default-color);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--accent-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  display: inline;
}

.blog-details .meta-bottom a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
  color: var(--accent-color);
}

.blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--default-color);
  content: ",";
}

.blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog-details .meta-bottom .share i {
  padding-left: 5px;
}

/*--------------------------------------------------------------
# Blog Author Section
--------------------------------------------------------------*/
.blog-author {
  padding: 10px 0 40px 0;
}

.blog-author .author-container {
  background-color: var(--surface-color);
  padding: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog-author h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog-author .social-links a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-right: 5px;
}

.blog-author p {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Comments Section
--------------------------------------------------------------*/
.blog-comments {
  padding: 10px 0;
}

.blog-comments .comments-count {
  font-weight: bold;
}

.blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog-comments .comment .comment-img img {
  width: 60px;
}

.blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog-comments .comment h5 a {
  font-weight: bold;
  color: var(--default-color);
  transition: 0.3s;
}

.blog-comments .comment h5 a:hover {
  color: var(--accent-color);
}

.blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 5px;
}

.blog-comments .comment.comment-reply {
  padding-left: 40px;
}

/*--------------------------------------------------------------
# Comment Form Section
--------------------------------------------------------------*/
.comment-form {
  padding-top: 10px;
}

.comment-form form {
  background-color: var(--surface-color);
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.comment-form form h4 {
  font-weight: bold;
  font-size: 22px;
}

.comment-form form p {
  font-size: 14px;
}

.comment-form form input {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 10px;
}

.comment-form form input:focus {
  color: var(--default-color);
  background-color: var(--surface-color);
  box-shadow: none;
  border-color: var(--accent-color);
}

.comment-form form input::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.comment-form form textarea {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  height: 120px;
}

.comment-form form textarea:focus {
  color: var(--default-color);
  box-shadow: none;
  border-color: var(--accent-color);
  background-color: var(--surface-color);
}

.comment-form form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.comment-form form .form-group {
  margin-bottom: 25px;
}

.comment-form form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.comment-form form .btn-primary:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widgets-container {
  background-color: var(--surface-color);
  padding: 30px;
  margin: 60px 0 30px 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.widget-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.widget-item {
  margin-bottom: 40px;
}

.widget-item:last-child {
  margin-bottom: 0;
}

.search-widget form {
  background: var(--background-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  padding: 3px 10px;
  position: relative;
  transition: 0.3s;
}

.search-widget form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
  background-color: var(--background-color);
  color: var(--default-color);
}

.search-widget form input[type=text]:focus {
  outline: none;
}

.search-widget form button {
  background: var(--accent-color);
  color: var(--contrast-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.search-widget form button i {
  line-height: 0;
}

.search-widget form button:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.search-widget form:is(:focus-within) {
  border-color: var(--accent-color);
}

.categories-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categories-widget ul li {
  padding-bottom: 10px;
}

.categories-widget ul li:last-child {
  padding-bottom: 0;
}

.categories-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.categories-widget ul a:hover {
  color: var(--accent-color);
}

.categories-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
}

.recent-posts-widget .post-item {
  display: flex;
  margin-bottom: 15px;
}

.recent-posts-widget .post-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item img {
  width: 80px;
  margin-right: 15px;
}

.recent-posts-widget .post-item h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.recent-posts-widget .post-item h4 a {
  color: var(--default-color);
  transition: 0.3s;
}

.recent-posts-widget .post-item h4 a:hover {
  color: var(--accent-color);
}

.recent-posts-widget .post-item time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.tags-widget {
  margin-bottom: -10px;
}

.tags-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags-widget ul li {
  display: inline-block;
}

.tags-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
  display: inline-block;
  transition: 0.3s;
}

.tags-widget ul a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  border: 1px solid var(--accent-color);
}

.tags-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  font-size: 14px;
}





/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Header */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

/* Sidebar */
.sidebar {
  position: fixed;
  left: -300px;
  width: 300px;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  z-index: 999;
}

.sidebar.open {
  left: 0;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav .nav-item {
  margin: 15px 0;
}

.sidebar-nav .nav-link {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.sidebar-nav .nav-link:hover {
  color: #007bff;
}

.h-200px {
  height: 250px !important;
}

.nav-heading {
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #6c757d;
}

/* Main Content */
.main-content {
  margin-top: 60px;
  padding: 20px;
  transition: margin-left 0.3s ease-in-out;
}

.sidebar.open~.main-content {
  margin-left: 250px;
}


.notice-container {
  background-color: #e6f7ff;
  /* Light blue background */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  position: relative;
}

.notice-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-header .notice-link {
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
}

.notice-header .notice-link:hover {
  text-decoration: underline;
}

.notice-header .close-btn {
  margin-left: auto;
  background: none;
  border: none;
  color: #666;
  font-size: 16px;
  cursor: pointer;
}

.notice-header .close-btn:hover {
  color: #000;
}

.notice-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon {
  text-align: center;
  margin: 0 5px;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.circle.green {
  background-color: green;
}

.circle.bronze {
  background-color: #cd7f32;
  /* Bronze color */
}

.circle.silver {
  background-color: silver;
}

.circle.gold {
  background-color: gold;
}

.circle.red {
  background-color: red;
}

.icon span {
  font-size: 17px;
  color: #555;
}


.w-35 {
  width: 200px;
}

.w-900 {
  width: 180px !important;
}

.fnt-10px {
  font-size: 15px !important;
}

.w-22 {
  width: 100px;
}

.ic {
  font-size: 30px;
}

.color-green {
  color: rgb(0, 185, 0) !important;
}

.color-blue {
  color: rgb(0, 3, 186) !important;
}

.color-red {
  color: rgb(255, 0, 0) !important;
}

li {
  margin: 10px 20px;
}

.imp {
  height: 400px !important;
}

body {
  direction: ltr;
}

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}


.www
{
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.eeee
{
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}